<template>
  <div>
    <fieldset :class="bgColor">
      <legend :class="`ml-2 px-2 ${bgColor} white--text`">{{ title }}</legend>
      <slot></slot>
    </fieldset>
  </div>
</template>

<script>
export default {
  props: ["bgColor", "title"]
};
</script>
