<template>
  <v-app>
    <v-app-bar app color="white">
      <v-btn
        tile
        v-for="i in 5"
        :key="`num-players-${i}`"
        @click="newGame(i)"
        light
        small
      >
        {{ i }}
      </v-btn>
    </v-app-bar>

    <v-footer app color="primary" dark>
      <v-spacer></v-spacer>
      <v-btn to="/good" light class="mr-1" small>Good</v-btn>
      <v-btn to="/bad" light small>Bad</v-btn>
    </v-footer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import wretch from "wretch";

  export default {
    name: "App",

    data: () => ({
      //
    }),

    methods: {
      newGame(numPlayers) {
        const apiURL = `/api/new-game/${numPlayers}`;

        wretch(apiURL)
          .auth(
            `Bearer: 0cc8f23309970a77402e5c73f662321a6514fb12dc3009cf9a6b5309560104ce`
          )
          .get();
      },
    },
  };
</script>
