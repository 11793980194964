<template>
  <div>
    <v-container style="padding-top: 25vh">
      <v-row class="align-self">
        <v-col cols="4" offset="4">
          <v-card light>
            <v-card-title> Choose Display </v-card-title>
            <v-card-actions>
              <v-btn to="/good"> Good Guys </v-btn>
              <v-btn to="/bad"> Bad Guys </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>
