<template>
  <div>
    <v-container>
      <div v-if="heroes.length > 0">
        <item-container bgColor="teal" title="Heroes">
          <v-container>
            <v-row>
              <v-col v-for="hero in heroList" :key="hero.id">
                <!-- {{ hero }} -->
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ hero[hero.primaryField] }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          hero.secondaryField == ""
                            ? hero.primaryField
                            : hero[hero.secondaryField]
                        }}&nbsp;
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon
                          v-for="(color, i) in JSON.parse(hero.colors)"
                          :key="`color-${hero._id}-${i}`"
                          :color="color"
                          small
                        >
                          mdi-circle
                        </v-icon>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="hero.special">
                      <v-icon color="yellow"> mdi-star </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </item-container>

        <div v-if="badHeroes.length > 0">
          <v-card color="yellow">
            <v-card-title> Also Pull: </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="badHeroes"
                :items-per-page="-1"
                hide-default-footer
              ></v-data-table>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <v-card v-else flat>
        <v-card-title> No Active Game </v-card-title>
      </v-card>
    </v-container>
    <v-toolbar flat v-if="heroes.length > 1">
      <v-spacer></v-spacer>
      <v-btn v-if="heroes.length > 1 && showPlayed" @click="markPlayed">
        Mark Played
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
  import itemContainer from "@/components/item-container";
  import wretch from "wretch";
  import handleResponse from "@/assets/handle-response";

  export default {
    components: { itemContainer },

    data: () => ({
      heroes: [],
      showPlayed: false,
      badHeroes: [],
      headers: [
        {
          text: "Name",
          value: "hero_name",
        },
        {
          text: "Affiliation",
          value: "affiliation",
        },
      ],
    }),

    computed: {
      heroList() {
        return [...this.heroes].sort((a, b) =>
          a.hero_name > b.hero_name ? 1 : -1
        );
      },
    },

    methods: {
      markPlayed() {
        wretch("/api/mark-played")
          .auth(
            `Bearer: 0cc8f23309970a77402e5c73f662321a6514fb12dc3009cf9a6b5309560104ce`
          )
          .post()
          .res()
          .then(() => {
            this.$router.go();
          });
      },
      getData() {
        handleResponse;
        wretch("/api/game/good")
          .auth(
            `Bearer: 0cc8f23309970a77402e5c73f662321a6514fb12dc3009cf9a6b5309560104ce`
          )
          .get()
          .json()
          .then(handleResponse)
          .then(({ askPlayed, heroes, villains }) => {
            if (new Date().getTime() >= parseInt(askPlayed))
              this.showPlayed = true;

            this.badHeroes = villains.filter(({ hero_name }) => hero_name);

            this.heroes = heroes.map((hero) => {
              if (!hero.colors) hero.colors = "[]";
              return hero;
            });
          })
          .catch((e) => {
            if (e == "No game found.") {
              this.heroes = [];
              return;
            }

            console.error(e);
          });
      },
    },

    mounted() {
      this.getData();
      setInterval(() => {
        this.getData();
      }, 1000);
    },
  };
</script>

<style>
  .colorDisplay {
    background-color: white;
  }
</style>
