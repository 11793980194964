import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Good from "@/views/Good";
import Bad from "@/views/Bad";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/good",
    name: "Good",
    component: Good
  },
  {
    path: "/bad",
    name: "Bad",
    component: Bad
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
