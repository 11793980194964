<template>
  <div>
    <v-container>
      <v-row v-if="schemes.length > 0">
        <v-col cols="12">
          <item-container bgColor="blue" title="Masterminds">
            <v-list-item
              v-for="mastermind in mastermindList"
              :key="mastermind.id"
              dark
            >
              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ mastermind[mastermind.primaryField] }}</h3>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="mastermind.special">
                <v-icon color="yellow"> mdi-star </v-icon>
              </v-list-item-action>
            </v-list-item>
          </item-container>
        </v-col>
        <v-col cols="12">
          <item-container bgColor="red" title="Villains">
            <v-list dense color="red">
              <v-list-item
                dark
                v-for="villain in villainList"
                :key="villain.key"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <h2>
                      {{ villain[villain.primaryField] }}
                    </h2>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      villain.secondaryField != ""
                        ? villain[villain.secondaryField]
                        : "&nbsp;"
                    }}&nbsp;
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="villain.special">
                  <v-icon color="yellow"> mdi-star </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </item-container>
        </v-col>
        <v-col cols="12">
          <item-container bgColor="grey" title="Henchmen">
            <v-list-item
              v-for="henchman in henchmenList"
              :key="henchman.key"
              dark
            >
              <v-list-item-content>
                <v-list-item-title>
                  <h3>
                    {{ henchman[henchman.primaryField] }}
                  </h3>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="henchman.special">
                <v-icon color="yellow"> mdi-star </v-icon>
              </v-list-item-action>
            </v-list-item>
          </item-container>
        </v-col>
        <v-col cols="12">
          <item-container bgColor="cyan" title="Schemes">
            <v-list-item
              v-for="(scheme, index) in schemeList"
              :key="`${scheme.id}-${index}`"
              dark
            >
              <v-list-item-content>
                <v-list-item-title>
                  <h3>
                    {{ scheme[scheme.primaryField] }}
                  </h3>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="scheme.special">
                <v-icon color="yellow"> mdi-star </v-icon>
              </v-list-item-action>
            </v-list-item>
          </item-container>
        </v-col>
      </v-row>
      <v-card v-else flat>
        <v-card-title> No Active Game </v-card-title>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import itemContainer from "@/components/item-container.vue";
  import wretch from "wretch";
  import handleResponse from "@/assets/handle-response";

  export default {
    components: { itemContainer },
    data: () => ({
      schemes: [],
      masterminds: [],
      villains: [],
      henchmen: [],
    }),

    computed: {
      schemeList() {
        return [...this.schemes].sort((a, b) =>
          a.scheme_name > b.scheme_name ? 1 : -1
        );
      },
      mastermindList() {
        return [...this.masterminds].sort((a, b) =>
          a.mastermind_name > b.mastermind_name ? 1 : -1
        );
      },
      villainList() {
        return [...this.villains].sort((a, b) =>
          a.villain_group > b.villain_group ? 1 : -1
        );
      },
      henchmenList() {
        return [...this.henchmen].sort((a, b) =>
          a.henchman_name > b.henchman_name ? 1 : -1
        );
      },
    },

    methods: {
      getData() {
        wretch("/api/game/bad")
          .auth(
            `Bearer: 0cc8f23309970a77402e5c73f662321a6514fb12dc3009cf9a6b5309560104ce`
          )
          .get()
          .json()
          .then(handleResponse)
          .then((data) => {
            this.schemes = data.schemes;
            this.masterminds = data.masterminds;
            this.villains = data.villains;
            this.henchmen = data.henchmen;
          })
          .catch((e) => {
            if (e == "No game found.") {
              this.schemes = [];
              this.masterminds = [];
              this.villains = [];
              this.henchmen = [];
            }
            console.error(e);
          });
      },
    },

    mounted() {
      this.getData();
      setInterval(() => {
        this.getData();
      }, 1000);
    },
  };
</script>
